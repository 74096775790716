export default {
  helpers: {
    label: {
      hotel_landingpage: {
        official_text: "Great deals if you use their official website!",
        recommend_text: "Lodgings that tsunagu Japan highly recommend!",
        published: "Publish",
      },
    },
  },
  hello: "Hello world",
  partner: "파트너",
  page_desc:
    "한 차원 높은 일본여행 웹사이트 ‘츠나구 재팬’! 일본 관광정보, 맛집, 쇼핑, 숙박, 교통, 날씨, 문화까지 꼼꼼하게 소개해드립니다.",
  page_title: "일본 관광･여행정보 츠나구재팬",
  video: "동영상",
  video_desc:
    "일본의 다양한 음식, 풍경, 문화, 전통 예능, 액티비티, 여행 팁 등에 대한 동영상을 소개합니다.",
  view_videos: "모든 영상 보기",
  confirm: "확정",
  newest_top: "최신 기사",
  more_btn: "More",
  coupon_headline: "할인 쿠폰",
  coupon_desc:
    "쇼핑, 숙소 예약, 투어 예약 등을 하실 때 사용 가능한 할인 쿠폰을 소개합니다.",
  related_label: "관련 기사",
  disclaimer_article: "기사 내의 정보는 공개 시점의 정보입니다.",
  none: "없음",
  toc: "목차",
  tours: {
    recommended: "Recommended Tours",
    currency: "JPY",
    from: "from",
    desc:
      "이 페이지에서는 일본에서 체험할 수 있는 투어 정보를 소개하고 있습니다.",
    tour: "추천 투어",
    details: "자세한 정보",
  },
  sort_by: "구역별 정렬",
  area_desc:
    "이 페이지에서는 일본의 각 지역에서의 관련 기사를 찾을 수 있습니다. 알고 싶은 지역을 지도상에서 클릭하면 그 지역의 관련 기사 일람 페이지로 이동할 수 있습니다.",
  clip_desc:
    "이 페이지는 당신이 북마크한 기사 일람을 표시하는 페이지입니다. 여행중에 이들 기사를 참조하면서 쾌적한 여행을 즐겨보세요.",
  empty_state:
    "죄송합니다, 아직 이것과 관련된 컨텐츠가 없습니다!저희는 항상 새로운 컨텐츠를 찍어내고 있으니, 어떤 업데이트도 놓치지 마세요!",
  search_by: "목적지, 활동 또는 시설명으로 검색",
  recommended_area: "추천 지역",
  view_area: "모든 지역 보기",
  search_in: "상세 검색",
  view_restaurant: "모든 레스토랑 보기",
  travel_tips: "여행 팁",
  transportation: "교통",
  manners: "매너",
  budget_travel: "저예산 여행",
  climate: "기후 & 날씨",
  top_travel: "인기 여행 일정",
  things: "관광과 체험",
  fooddrink: "음식",
  hotels: "호텔 & 료칸",
  shopping: "쇼핑",
  travel_tip: "여행 팁",
  in_depth: "일본 문화",
  festival: "축제 & 이벤트",
  view_tips: "모든 팁 보기",
  featured_topics: "주제별 모음",
  japan: "일본",
  latest: "Latest Posts",
  media_partner: "MEDIA PARTNER",
  sushi: "#스시",
  onsen: "#온천",
  ramen: "#라멘",
  activites: "#활동/경험",
  souvenier: "#기념품",
  beauty: "#미용 & 패션",
  history: "#역사 & 문화",
  hotels_ryokan: "#호텔 & 료칸",
  popular_restaurants: "지역별 인기 레스토랑 ",
  savor: {
    search_title: "Try Out One of Japan's Most Popular Search Engines!",
    search_results: "%{start} - %{end} of %{all}",
    sort_price: "Price",
    sort_price_ask: "Low to High",
    sort_price_desc: "High to Low",
    sidebar_headline: "Japanese Restaurant Guide",
    slogan:
      "Savor Japan is an exhaustive restaurant<br />\nguide for restaurants in Japan.",
    tokyo: "도쿄",
    tokyo_sub: {
      kanagawa: "가나가와",
      chiba: "지바",
      tochigi: "도치기",
      gunma: "군마",
      saitama: "사이타마",
      ibaraki: "이바라키",
      shizuoka: "시즈오카",
      yamanashi: "야마나시",
    },
    kyoto_sub: {
      kyoto: "교토",
      osaka: "오사카",
      hyogo: "효고",
      nara: "나라",
      shiga: "시가",
      wakayama: "와카야마",
      mie: "미에",
    },
    honshu_sub: {
      miyagi: "미야기",
      fukushima: "후쿠시마",
      yamagata: "야마가타",
      iwate: "이와테",
      akita: "아키타",
      aomori: "아오모리",
      aichi: "아이치",
      shizuoka: "시즈오카",
      gifu: "기후",
      ishikawa: "이시카와",
      nagano: "나가노",
      niigata: "니가타",
      toyama: "도야마",
      fukui: "후쿠이",
      yamanashi: "야마나시",
      hiroshima: "히로시마",
      okayama: "오카야마",
      shimane: "시마네",
      tottori: "돗토리",
      yamaguchi: "야마구치",
    },
    shikoku_sub: {
      ehime: "에히메",
      kagawa: "가가와",
      kochi: "고치",
      tokushima: "도쿠시마",
    },
    kyushu_sub: {
      fukuoka: "후쿠오카",
      kagoshima: "가고시마",
      kumamoto: "구마모토",
      miyazaki: "미야자키",
      nagasaki: "나가사키",
      oita: "오이타",
      saga: "사가",
    },
    near_tokyo: "Near Tokyo",
    kyoto: "Kyoto and Osaka area",
    hokkaido: "홋카이도",
    chubu: "주부",
    kanto: "간토",
    kansai: "간사이",
    tohoku: "도호쿠",
    chugoku: "주고쿠",
    northern_honshu: "도호쿠",
    central_honshu: "주부",
    western_honshu: "주고쿠",
    shikoku: "시코쿠",
    kyushu: "규슈",
    okinawa: "오키나와",
    learn_more: "learn more",
    show_less: "show less",
    restaurant_search: "레스토랑 검색",
    search_by: "인기 지역별로 찾기",
    no_searchresult:
      "Restaurants were not found. Please try different search criteria.",
    sidebar: {
      s_keyword: "\t키워드로 찾기",
      search: "레스토랑 검색",
      location: "지역",
      cuisine: "요리",
      filter: "Filter",
      budget: "예산",
      dinner: "Dinner",
      lunch: "Lunch",
      extras: "추가 사항",
      cc: "신용카드 결제",
      coupons: "Coupons / Vouchers",
      keywords: "Keywords",
      placeholder_keyword: "예: 도시, 역, 요리, 혹은 특정 식당",
      placeholder_city: "예: 도시, 널리 알려진 명소",
      placeholder_cuisine: "예: 스시, 라멘",
    },
    filter: {
      no_smoking: "금연",
      menu: "영어 메뉴",
      staff: "영어가 가능한 종업원",
      languages_available: "영어가 가능한 종업원",
      late_night: "심야 서비스",
      late_night_service: "심야 서비스",
      diet: "특별식 주문",
      special_diet: "특별식 주문",
      western_cutlery: "서양식 식기",
      lunch: "런치",
      lunch_menu: "런치",
      wifi: "무료 Wi-Fi",
      free_wifi_available: "무료 Wi-Fi",
      visa: "Visa",
      mastercard: "MasterCard",
      anex: "American Express",
      diners: "Diners Club",
      coupons: "Coupons / Vouchers",
    },
  },
  "hotel-lp": {
    meta_title: "tsunagu Japan's Top Picks for Japanese Hotels & Inns",
    meta_desc:
      "Using our vast network, we have picked out the best Japanese hotels and inns to showcase. Many can’t be found on other websites, so take a look!",
    breadcrumb: "Editorial Picks",
    title_html:
      "tsunagu Japan <br/> editorial department carefully selected!<br/>Special homepage introduction",
    desc_headline: "A noteworthy trip starts with exceptional lodging.",
    desc:
      "Japan has around 50,000 hotels and inns, which makes coming to a decision incredibly hard. Using our vast network, our editorial team has scoured through lodgings all over Japan and picked out a select few to introduce to you! There are several that you won’t find on other websites, so it’s definitely worth a look!",
    starting: "Starting from",
    pickup: "New Pickup",
    website: "Official Website",
    website_sub_html: "Great deals if you use their official website!",
    website_sub_recommend_text: "We highly recommend this lodge!",
    related: "관련 기사",
    hotel_price_tax_html:
      "Excluding taxes and fees <br /> It is a charge for two people in one room.",
    notice: {
      change:
        "The information on this website may change even after the publish date.",
      estimated:
        "The estimated accommodation fee is based on the fee for 1 person renting a room that can accommodate 2 people during the off-season. It includes meals, but does not include consumption tax, bathing tax, or service charges. Depending on factors like how many people use 1 room, the season, the type of room, and the accommodation plan, the overall accommodation fee can differ.",
      communication:
        "Staff members who can communicate in languages other than Japanese may not always be present.",
    },
    features: {
      bar: "Bar",
      parking: "Parking",
      bus: "Pick Up Service",
      wifi: "Wi-Fi",
      onsen: "온천",
      smoking: "Smoking Room",
      nosmoking: "Non-smoking Room",
      smokingspace: "Smoking Spaces",
      english: "English OK",
      chinese: "Chinese Ok",
    },
  },
  buttons: {
    "btn-default": "default",
    "btn-cust-blue": "blue",
    "btn-cust-green": "green",
    "book-with": "light orange with shadow",
    "btn-ads": "red ad button",
    "savor-button-booking": "savor button",
    "btn-3d-orange": "orange with shadow",
    "btn-dull-yellow": "dull yellow",
    "btn-2d-orange": "orange",
    "btn-light-orange": "light orange"
  },
  map: {
    search_from: "Search from list",
    headline: "Search from Map",
    information1: "※ Sendai is included in Miyagi",
    information2: "※ Yokohama, Kamakura, Hakone is included in Kanagawa",
    information3: "※ Kanazawa is included in Ishikawa",
    information4: "※ Nagoya is included in Aichi",
    hokkaido: "1. 홋카이도",
    sendai: "5. 센다이",
    tokyo: "13. 도쿄",
    yokohama: "14. 요코하마",
    kamakura: "14. 가마쿠라",
    hakone: "14. 하코네",
    kanazawa: "17. 가나가와",
    nagoya: "23. 나고야",
    kyoto: "25. 교토",
    osaka: "29. 오사카",
    fukuoka: "40. 후쿠오카",
    okinawa: "오키나와",
    popular: "Popular cities for tourists",
  },
  "basic-information": {
    name: "명칭",
    hours: "영업시간",
    closed: "휴일",
    price: "가격",
    address: "주소",
    access: "교통편",
    "url-ja": "웹사이트 (일본어)",
    "url-en": "웹사이트 (영어)",
    "url-th": "Website (Thai)",
    "url-zh-hant": "웹사이트 (중국어 번체)",
    "url-zh-hans": "웹사이트 (중국어 간체)",
    others: "그 외",
    checkin: "Check-in",
    checkout: "Check-out",
  },
  search: {
    title: "검색",
    desc: "%{term}로(으로)%{num}건의 검색결과를 찾았습니다",
  },
  views: {
    pagination: {
      previous: "",
      next: "",
    },
  },
  rankings: {
    title: "인기 기사",
    desc: "",
  },
  tags: {
    title: "%{param}",
    desc: "",
  },
  area: {
    title: "%{param}",
    desc: "",
    area: "지역",
  },
  article: {
    title: "기사 일람",
    desc: "",
    translator: "Translator",
    author: "Author",
    travelSite: {
      heading: "일본 거주 편집부 추천･숙소･상품･액티비티",
    },
  },
  hotel: {
    name1: "Agoda",
    short1: "A",
    name2: "Booking.com",
    short2: "B",
    bookWith1: "Agoda에서 예약",
    bookWith2: "Booking.com에서 예약",
    bookWith3: "Relux에서 예약",
  },
  hotelpage: {
    labels: {
      name: "호텔명",
      desc: "세부 사항",
      price: "가격",
      location: "주소",
      reference: "인용",
      book: "book at",
      info: "정보",
    },
  },
  source: "출처:",
  sns: {
    share: "공유",
    pin: "Pin",
    tweet: "트윗",
  },
  lang: {
    "zh-hant": "繁體中文",
    "zh-hans": "简体中文",
    ko: "한국어",
    vi: "Tiếng Việt",
    en: "English",
    ja: "日本語",
    th: "ภาษาไทย",
  },
  footer: {
    about: "tsunagu Japan에 관하여",
    tsunagudesc:
      "tsunagu Japan은 방일 여행객을 위한 정보 플랫폼입니다. 방일 여행객이 일본에서 더욱 알차게 일정을 보내실 수 있도록 관광, 음식, 쇼핑, 여행 팁 등의 다양한 정보를 제공하고 있습니다.",
    contact: "문의하기",
    login: "로그인",
    logout: "로그아웃",
    company: "회사 개요",
    privacy: "개인정보 처리방침",
    terms: "이용 약관",
    jobs: "채용 정보",
    dmc: "For Travel Agencies",
  },
};
