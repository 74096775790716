export default {
  helpers: {
    label: {
      hotel_landingpage: {
        official_text: "Great deals if you use their official website!",
        recommend_text: "Lodgings that tsunagu Japan highly recommend!",
        published: "Publish",
      },
    },
  },
  hello: "Hello world",
  confirm: "Confirm",
  partner: "Mitra Kami",
  video: "Video",
  view_videos: "Lihat semua video",
  video_desc:
    "Menghadirkan video yang memberikan informasi tentang kuliner Jepang, pemandangan indah, budaya, seni pertunjukan tradisional, kegiatan, tips, dan masih banyak lagi!",
  more_btn: "More",
  "sns-bot": "tsunagu Japan SNS",
  newest_top: "Artikel Terbaru",
  page_desc:
    "Halaman ini berisi kumpulan artikel tentang berbagai hal yang bisa Anda lalukan di Jepang. Penuh dengan informasi bermanfaat untuk Anda menikmati Jepang dari segala aspek, termasuk rekomendasi tempat-tempat wisata, kuliner, budaya, hotel & ryokan, dan masih banyak lagi!",
  related_label: "Recommended",
  page_title: "tsunagu Japan",
  tours: {
    recommended: "Rekomendasi Tur",
    currency: "JPY",
    from: "from",
    desc: "Halaman ini mencantumkan tur di Jepang yang bisa Anda daftarkan.",
    tour: "Recommended Tours",
    details: "Details",
  },
  page_prefix: "tsunagu Japan",
  sort_by: "Urutkan berdasarkan area",
  none: "none",
  search_map: "Cari dari Peta",
  search_in: "Cari di",
  coupon_headline: "Kupon Diskon",
  coupon_desc:
    "Halaman ini mencantumkan berbagai kupon diskon yang dapat Anda gunakan untuk semua jenis situasi, mulai dari berbelanja hingga memesan hotel atau tur.",
  area_desc:
    "Di halaman ini Anda dapat mencari artikel berdasarkan area. Jika Anda menekan tombol area di peta, halaman baru yang berisi artikel terkait akan muncul.",
  new_arrivals: "New Arrivals",
  clips_title: "Bookmark Anda",
  clip_desc:
    "Halaman ini menunjukkan semua artikel yang Anda bookmarked. Silakan gunakan artikel-artikel ini sebagai panduan untuk melakukan perjalanan lebih menyenangkan di Jepang!",
  empty_state:
    "Maaf, kami belum memiliki konten terkait saat ini. Tsunagu Japan akan selalu memberikan konten-konten menarik untuk Anda, jadi tetap stay tuned dan jangan sampai ketinggalan informasi paling up to date!",
  search_by: "Cari berdasarkan destinasi, aktivitas, atau atraksi",
  recommended_area: "Area Rekomendasi",
  view_area: "Lihat semua area",
  view_restaurant: "Lihat semua restoran",
  travel_tips: "Travel Tips",
  transportation: "Transportasi",
  manners: "Manners",
  budget_travel: "Budget",
  climate: "Iklim & Cuaca",
  top_travel: "Rencana Travel Populer",
  things: "Wisata",
  fooddrink: "Kuliner",
  hotels: "Hotel & Ryokan",
  shopping: "Shopping",
  travel_tip: "Travel Tips",
  in_depth: "Budaya Jepang",
  festival: "Festivals & Events",
  view_tips: "Lihat semua tips",
  featured_topics: "Topik Populer",
  japan: "Jepang",
  latest: "Latest Posts",
  media_partner: "MEDIA PARTNER",
  sushi: "#Sushi",
  onsen: "#Onsen",
  ramen: "#Ramen",
  activites: "#Aktivitas/Pengalaman",
  souvenier: "#Suvenir",
  beauty: "#Busana & Kecantikan",
  history: "#Sejarah & Budaya",
  hotels_ryokan: "#Hotel & Ryokan",
  popular_restaurants: "Restoran Populer berdasarkan Area",
  savor: {
    search_title: "Try Out One of Japan's Most Popular Search Engines!",
    search_results: "%{start} - %{end} of %{all}",
    sort_price: "Price",
    sort_price_ask: "Low to High",
    sort_price_desc: "High to Low",
    sidebar_headline: "Japanese Restaurant Guide",
    slogan:
      "Savor Japan is an exhaustive restaurant<br />\nguide for restaurants in Japan.",
    tokyo: "Tokyo",
    tokyo_sub: {
      kanagawa: "Kanagawa",
      chiba: "Chiba",
      tochigi: "Tochigi",
      gunma: "Gunma",
      saitama: "Saitama",
      ibaraki: "Ibaraki",
      shizuoka: "Shizuoka",
      yamanashi: "Yamanashi",
    },
    kyoto_sub: {
      kyoto: "Kyoto",
      osaka: "Osaka",
      hyogo: "Hyogo",
      nara: "Nara",
      shiga: "Shiga",
      wakayama: "Wakayama",
      mie: "Mie",
    },
    honshu_sub: {
      miyagi: "Miyagi",
      fukushima: "Fukushima",
      yamagata: "Yamagata",
      iwate: "Iwate",
      akita: "Akita",
      aomori: "Aomori",
      aichi: "Aichi",
      shizuoka: "Shizuoka",
      gifu: "Gifu",
      ishikawa: "Ishikawa",
      nagano: "Nagano",
      niigata: "Niigata",
      toyama: "Toyama",
      fukui: "Fukui",
      yamanashi: "Yamanashi",
      hiroshima: "Hiroshima",
      okayama: "Okayama",
      shimane: "Shimane",
      tottori: "Tottori",
      yamaguchi: "Yamaguchi",
    },
    shikoku_sub: {
      ehime: "Ehime",
      kagawa: "Kagawa",
      kochi: "Kochi",
      tokushima: "Tokushima",
    },
    kyushu_sub: {
      fukuoka: "Fukuoka",
      kagoshima: "Kagoshima",
      kumamoto: "Kumamoto",
      miyazaki: "Miyazaki",
      nagasaki: "Nagasaki",
      oita: "Oita",
      saga: "Saga",
    },
    near_tokyo: "Near Tokyo",
    kyoto: "Kyoto and Osaka area",
    hokkaido: "Hokkaido",
    chubu: "Chubu",
    kanto: "Kanto",
    kansai: "Kansai",
    tohoku: "Tohoku",
    chugoku: "Chugoku",
    northern_honshu: "Nothern Honshu (Tohoku)",
    central_honshu: "Central Honshu (Chubu)",
    western_honshu: "Western Honshu (Chugoku)",
    shikoku: "Shikoku",
    kyushu: "Kyushu",
    okinawa: "Okinawa",
    learn_more: "learn more",
    show_less: "show less",
    restaurant_search: "Cari Restoran",
    search_by: "Cari lewat Area Populer",
    no_searchresult:
      "Restaurants were not found. Please try different search criteria.",
    sidebar: {
      s_keyword: "Cari lewat Kata Kunci",
      search: "Cari Restoran",
      location: "Lokasi",
      cuisine: "Masakan",
      filter: "Filter",
      budget: "Budget",
      dinner: "Dinner",
      lunch: "Lunch",
      extras: "Ekstra",
      cc: "Kartu kredit",
      coupons: "Coupons / Vouchers",
      keywords: "Kata kunci",
      placeholder_keyword: "e.g. city, station,cuisine or specific restaurant",
      placeholder_city: "e.g. city, landmark",
      placeholder_cuisine: "contoh: Sushi, ramen",
    },
    filter: {
      no_smoking: "No Smoking",
      menu: "Menu bahasa Inggirs",
      staff: "Staf berbahasa Inggris",
      languages_available: "Staf berbahasa Inggris",
      late_night: "Late-night service",
      late_night_service: "Late-night service",
      diet: "Permintaan diet khusus",
      special_diet: "Permintaan diet khusus",
      western_cutlery: "Peralatan makan barat",
      lunch: "Makan siang",
      lunch_menu: "Lunch",
      wifi: "Gratis Wi-Fi",
      free_wifi_available: "Gratis Wi-Fi",
      visa: "Visa",
      mastercard: "MasterCard",
      anex: "American Express",
      diners: "Diners Club",
      coupons: "Coupons / Vouchers",
    },
  },
  "hotel-lp": {
    meta_title: "tsunagu Japan's Top Picks for Japanese Hotels & Inns",
    meta_desc:
      "Using our vast network, we have picked out the best Japanese hotels and inns to showcase. Many can’t be found on other websites, so take a look!",
    breadcrumb: "Editorial Picks",
    title_html:
      "tsunagu Japan <br/> editorial department carefully selected!<br/>Special homepage introduction",
    desc_headline: "A noteworthy trip starts with exceptional lodging.",
    desc:
      "Japan has around 50,000 hotels and inns, which makes coming to a decision incredibly hard. Using our vast network, our editorial team has scoured through lodgings all over Japan and picked out a select few to introduce to you! There are several that you won’t find on other websites, so it’s definitely worth a look!",
    starting: "Starting from",
    pickup: "New Pickup",
    website: "Official Website",
    website_sub_html: "Great deals if you use their official website!",
    website_sub_recommend_text: "We highly recommend this lodge!",
    related: "Artikel terkait",
    hotel_price_tax_html:
      "Excluding taxes and fees <br /> It is a charge for two people in one room.",
    notice: {
      change:
        "The information on this website may change even after the publish date.",
      estimated:
        "The estimated accommodation fee is based on the fee for 1 person renting a room that can accommodate 2 people during the off-season. It includes meals, but does not include consumption tax, bathing tax, or service charges. Depending on factors like how many people use 1 room, the season, the type of room, and the accommodation plan, the overall accommodation fee can differ.",
      communication:
        "Staff members who can communicate in languages other than Japanese may not always be present.",
    },
    features: {
      bar: "Bar",
      parking: "Parking",
      bus: "Pick Up Service",
      wifi: "Wi-Fi",
      onsen: "Hot Spring",
      smoking: "Smoking Room",
      nosmoking: "Non-smoking Room",
      smokingspace: "Smoking Spaces",
      english: "English OK",
      chinese: "Chinese Ok",
    },
  },
  buttons: {
    "btn-default": "default",
    "btn-cust-blue": "blue",
    "btn-cust-green": "green",
    "book-with": "light orange with shadow",
    "btn-ads": "red ad button",
    "savor-button-booking": "savor button",
    "btn-3d-orange": "orange with shadow",
    "btn-dull-yellow": "dull yellow",
    "btn-2d-orange": "orange",
    "btn-light-orange": "light orange"
  },
  map: {
    search_from: "Search from list",
    headline: "Search from Map",
    information1: "※ Sendai is included in Miyagi",
    information2: "※ Yokohama, Kamakura, Hakone is included in Kanagawa",
    information3: "※ Kanazawa is included in Ishikawa",
    information4: "※ Nagoya is included in Aichi",
    hokkaido: "1. Hokkaido",
    sendai: "5. Sendai",
    tokyo: "13. Tokyo",
    yokohama: "14. Yokohama",
    kamakura: "14. Kamakura",
    hakone: "14. Hakone",
    kanazawa: "17. Kanazawa",
    nagoya: "23. Nagoya",
    kyoto: "25. Kyoto",
    osaka: "29. Osaka",
    fukuoka: "40. Fukuoka",
    okinawa: "Okinawa",
    popular: "Popular cities for tourists",
  },
  "basic-information": {
    name: "Nama",
    hours: "Jam buka",
    closed: "Hari tutup",
    price: "Harga",
    address: "Alamat",
    access: "Akses",
    "url-ja": "Website (bahasa Jepang)",
    "url-en": "Website (bahasa Inggris)",
    "url-th": "Website (Thai)",
    "url-zh-hant": "Website (bahasa Mandarin tradisional)",
    "url-zh-hans": "Website (bahasa Mandarin disederhanakan)",
    "url-ko": "Website (Korean)",
    "url-vi": "Website (Vietnamese)",
    others: "Lainnya",
    checkin: "Check-in",
    checkout: "Check-out",
  },
  toc: "Daftar isi",
  search: {
    title: "Mencari",
    desc: "%{num} hasil pencarian untuk %{term}",
  },
  views: {
    pagination: {
      previous: "",
      next: "",
    },
  },
  rankings: {
    title: "Artikel populer",
    desc: "",
  },
  tags: {
    title: "%{param}",
    desc: "",
  },
  area: {
    title: "%{param}",
    desc: "",
    area: "Area",
  },
  article: {
    title: "Daftar artikel",
    desc: "",
    translator: "Translator",
    author: "Author",
    travelSite: {
      heading: "Rekomendasi dari editor kami",
    },
  },
  hotel: {
    name1: "Agoda",
    short1: "A",
    name2: "Booking.com",
    short2: "B",
    bookWith1: "Pesan melalui Agoda",
    bookWith2: "Pesan melalui Booking.com",
    bookWith3: "Pesan melalui Relux",
  },
  hotelpage: {
    labels: {
      name: "Nama",
      desc: "Deskripsi",
      price: "Harga",
      location: "Alamat",
      reference: "Sumber",
      book: "book at",
      info: "Informasi",
    },
  },
  source: "Sumber:",
  sns: {
    share: "Bagikan",
    pin: "pin",
    tweet: "tweet",
  },
  lang: {
    "zh-hant": "Bahasa Mandarin (Tradisional)",
    "zh-hans": "Bahasa Mandarin (Disederhanakan)",
    ko: "Bahasa Korea",
    vi: "Bahasa Vietnam",
    en: "Bahasa Inggris",
    ja: "Bahasa Jepang",
    th: "Bahasa Thailand",
  },
  footer: {
    about: "Tentang tsunagu Japan",
    tsunagudesc:
      "tsunagu Japan adalah platform untuk berbagi informasi perjalanan di Jepang. Informasi ini mencakup saran rencana perjalanan, tips bermanfaat untuk tinggal di Jepang, dan pengetahuan tentang budaya Jepang yang membuat setiap perjalanan terasa lebih menyenangkan. Kami ingin membawa seluruh aspek Jepang kepada Anda. ",
    contact: "Kontak",
    login: "Masuk",
    logout: "Keluar",
    company: "Profil Perusahaan",
    privacy: "Kebijakan pribadi",
    terms: "Ketentuan",
    jobs: "Pekerjaan",
    dmc: "For Travel Agencies",
  },
};
