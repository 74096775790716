export default {
  helpers: {
    label: {
      hotel_landingpage: {
        official_text: "官方網站更超值!",
        recommend_text: "tsunagu Japan特別介紹的旅宿！",
      },
    },
  },
  hello: "Hello world",
  things: "玩樂",
  fooddrink: "美食",
  partner: "合作夥伴",
  video: "影片",
  video_desc:
    "匯集日本美食、絕景、文化、傳統藝術、體驗活動、小知識等多樣影片。",
  view_videos: "觀看所有影片",
  page_desc:
    "此頁面集結日本旅行的必玩必賞攻略，包括推薦旅遊景點、值得一訪的場所，讓您能更加享受在日本旅行的時光與樂趣。",
  newest_top: "最新文章",
  more_btn: "More",
  related_label: "繁關連文章",
  confirm: "确定",
  page_title: "日本旅遊資訊網",
  disclaimer_article: "本文資訊均以公開時為準。",
  page_prefix: "tsunagu Japan  繫日本",
  coupon_headline: "優惠券",
  coupon_desc: "一網打盡購物、預約旅館及行程時能使用的優惠券",
  search_in: "搜尋",
  hotels: "住宿",
  shopping: "購物",
  none: "不适用",
  travel_tip: "實用資訊",
  area_desc:
    "您能在此頁面搜尋日本各地精選文章。從地圖上點選欲知區域，即可導入當地文章一覽。",
  clip_desc:
    "此頁面為您釘選的文章一覽。協助您一邊參考文章，一邊來趟得心應手的旅程。",
  empty_state:
    "抱歉，我們目前尚無此類文章！但我們不斷致力於分享日本最新消息，還請您持續追蹤我們發布的最新資訊！",
  in_depth: "深度日本",
  japan: "日本",
  search_by: "搜尋目的地、活動、旅遊景點",
  latest: "最新文章",
  recommended_area: "推薦地區",
  view_area: "查看所有區域",
  travel_tips: "實用資訊",
  transportation: "交通",
  manners: "禮儀",
  budget_travel: "低預算旅行",
  climate: "氣候指南",
  top_travel: "熱門旅遊路線",
  festival: "節慶活動",
  view_tips: "查看所有實用資訊",
  media_partner: "合夥媒體",
  featured_topics: "精選主題",
  sushi: "#壽司",
  onsen: "#溫泉",
  ramen: "#拉麵",
  activites: "#活動體驗",
  souvenier: "#伴手禮",
  beauty: "#時尚美容",
  history: "#歷史文化",
  hotels_ryokan: "飯店住宿",
  popular_restaurants: "熱門景點餐廳",
  view_restaurant: "查看所有餐廳",
  tours: {
    recommended: "Recommended Tours",
    currency: "JPY",
    from: "from",
    desc: "為您介紹能在日本參加的體驗",
    tour: "推薦行程",
    details: "行程範例",
  },
  savor: {
    search_title: "搜索日本優質餐廳",
    search_results: "%{all} 間餐廳中的 %{start} - %{end}間",
    sort_price: "價格",
    sort_price_ask: "由低至高",
    sort_price_desc: "由高至低",
    learn_more: "了解更多",
    show_less: "關閉",
    tokyo: "東京",
    tohoku: "東北",
    kanto: "關東",
    chubu: "中部",
    kansai: "關西",
    chugoku: "中国",
    tokyo_sub: {
      kanagawa: "神奈川",
      chiba: "千葉",
      tochigi: "栃木",
      gunma: "群馬",
      saitama: "埼玉",
      ibaraki: "茨城",
      shizuoka: "靜岡",
      yamanashi: "山梨",
    },
    kyoto_sub: {
      kyoto: "京都",
      osaka: "大阪",
      hyogo: "兵庫",
      nara: "奈良",
      shiga: "滋賀",
      wakayama: "和歌山",
      mie: "三重",
    },
    honshu_sub: {
      miyagi: "宮城",
      fukushima: "福島",
      yamagata: "山形",
      iwate: "岩手",
      akita: "秋田",
      aomori: "青森",
      aichi: "愛知",
      shizuoka: "靜岡",
      gifu: "岐阜",
      ishikawa: "石川",
      nagano: "長野",
      niigata: "新潟",
      toyama: "富山",
      fukui: "福井",
      yamanashi: "山梨",
      hiroshima: "廣島",
      okayama: "岡山",
      shimane: "島根",
      tottori: "鳥取",
      yamaguchi: "山口",
    },
    shikoku_sub: {
      ehime: "爱媛",
      kagawa: "香川",
      kochi: "高知",
      tokushima: "德島",
    },
    kyushu_sub: {
      fukuoka: "福岡",
      kagoshima: "鹿兒島",
      kumamoto: "熊本",
      miyazaki: "宮崎",
      nagasaki: "長崎",
      oita: "大分",
      saga: "佐賀",
    },
    near_tokyo: "東京附近",
    kyoto: "京都與大阪地區",
    hokkaido: "北海道",
    northern_honshu: "本州北部（東北）",
    central_honshu: "本州中部（中部）",
    western_honshu: "本州西部（中國）",
    shikoku: "四國",
    kyushu: "九州",
    okinawa: "沖繩與東南島嶼",
    restaurant_search: "搜尋餐廳",
    no_searchresult: "找不到您要的餐廳，請更改搜尋標準。",
    search_by: "從人氣景點查詢",
    sidebar: {
      s_keyword: "以关键字搜寻",
      search: "搜尋餐廳",
      location: "地點",
      cuisine: "菜餚",
      filter: "進階搜尋",
      budget: "預算",
      dinner: "晚餐",
      lunch: "午餐",
      extras: "其他",
      cc: "信用卡種類",
      coupons: "優惠卷",
      keywords: "關鍵字",
      placeholder_keyword: "（例）城市、車站、料理或特定餐廳",
      placeholder_city: "（例）城市或地標",
      placeholder_cuisine: "（例）喜歡的料理",
    },
    filter: {
      no_smoking: "全面禁煙",
      menu: "中文（繁體字）菜單",
      staff: "華語服務人員",
      languages_available: "華語服務人員",
      late_night: "包廂",
      late_night_service: "包廂",
      diet: "料理特殊要求",
      special_diet: "料理特殊要求",
      western_cutlery: "外帶",
      lunch: "午餐",
      lunch_menu: "午餐",
      wifi: "免費WiFi",
      free_wifi_available: "免費WiFi",
      visa: "Visa",
      mastercard: "MasterCard",
      anex: "American Express",
      diners: "Diners Club",
      coupons: "優惠卷",
    },
    sidebar_headline: " ",
    slogan:
      "品味日本是詳盡的日本餐廳指南。 <br />如果您日造訪日本的外國觀光客，<br />本網站正是您最佳指南。 ",
  },
  buttons: {
    "btn-default": "default",
    "btn-cust-blue": "blue",
    "btn-cust-green": "green",
    "book-with": "light orange with shadow",
    "btn-ads": "red ad button",
    "savor-button-booking": "savor button",
    "btn-3d-orange": "orange with shadow",
    "btn-dull-yellow": "dull yellow",
    "btn-2d-orange": "orange",
    "btn-light-orange": "light orange"
  },
  toc: "目錄",
  map: {
    headline: "Search from Map",
    information1: "※ 金澤位在石川縣。",
    information2: "※ 仙台位在宮城縣。",
    information3: "※ 橫濱、鎌倉、箱根位在神奈川縣。",
    information4: "※ 名古屋位在愛知縣。",
    hokkaido: "1. 北海道",
    sendai: "5. 仙台",
    tokyo: "13. 東京",
    yokohama: "14. 橫濱",
    kamakura: "14. 鎌倉",
    hakone: "14. 箱根",
    kanazawa: "17. 金澤",
    nagoya: "23. 名古屋",
    kyoto: "25. 京都",
    osaka: "29. 大阪",
    fukuoka: "40. 福岡",
    okinawa: "沖繩",
    popular: "Popular cities for tourists",
  },
  "basic-information": {
    name: "名稱",
    hours: "營業時間",
    closed: "公休日",
    price: "價位",
    address: "地址",
    access: "交通方式",
    "url-ja": "網站（日文)",
    "url-en": "網站（英文)",
    "url-zh-hant": "網站（繁體字)",
    "url-zh-hans": "網站（簡體字)",
    checkin: "Check-in",
    checkout: "Check-out",
    others: "其它",
  },
  search: {
    title: "搜尋",
    desc: "符合%{term}的文章共%{num}筆",
  },
  views: {
    pagination: {
      previous: "",
      next: "",
    },
  },
  rankings: {
    title: "熱門文章",
    desc: "",
  },
  tags: {
    title: "%{param}",
    desc: "",
  },
  area: {
    title: "%{param}",
    desc: "",
    area: "區域",
  },
  article: {
    title: "文章列表",
    desc: "",
    translator: "譯者",
    author: "作者",
    travelSite: {
      heading: "在地編輯推薦旅館・商品・體驗行程",
    },
  },
  "hotel-lp": {
    meta_title: "tsunagu Japan編輯部嚴選！日本酒店＆旅館",
    meta_desc:
      "日本據說約有5萬間的酒店及旅館，想要從中挑選其一實在很困難。在此，tsunagu Japan編輯部在獨自擁有的全日本住宿資訊平台中，為您嚴選了數一數二的酒店及旅館！尤其是還有一般訂房網所沒有刊登的旅館及酒店，請您無論如何都要參考看看喔！",
    title_html:
      "tsunagu Japan <br/> editorial department carefully selected!<br/>Special homepage introduction",
    desc_headline: "特別的旅行，就從特別的住宿開始！",
    desc:
      "日本據說約有5萬間的酒店及旅館，想要從中挑選其一實在很困難。在此，tsunagu Japan編輯部在獨自擁有的全日本住宿資訊平台中，為您嚴選了數一數二的酒店及旅館！尤其是還有一般訂房網所沒有刊登的旅館及酒店，請您無論如何都要參考看看喔！",
    starting: "Starting from",
    pickup: "New Pickup",
    website: "官方網站",
    website_sub_html: "官方網站更超值!",
    website_sub_recommend_text: "tsunagu Japan特別介紹的旅宿！",
    related: "相關文章",
    hotel_price_tax_html:
      "Excluding taxes and fees <br /> It is a charge for two people in one room.",
    notice: {
      change: "本網站刊載資訊可能會有日後更動的情況，建議您事前確認。",
      estimated:
        "此價格為淡季、1泊2食、2人1房（不含消費稅、溫泉稅及服務費）時1人的住宿費用。刊載的房間費用也會因利用人數、季節、客房型態、料理方案等而有所變動。",
      communication: "並非隨時都有會外文的職員常駐。不便之處，敬請見諒。",
    },
    features: {
      bar: "酒吧",
      parking: "酒吧",
      bus: "含接送",
      wifi: "Wi-Fi",
      onsen: "溫泉",
      smoking: "吸菸房",
      nosmoking: "禁菸房",
      smokingspace: "吸菸處",
      english: "可對應英文",
      chinese: "可對應中文",
    },
  },
  hotel: {
    name1: "Agoda",
    short1: "A",
    name2: "Booking.com",
    short2: "B",
    bookWith1: "用Agoda預約",
    bookWith2: "用Booking預約",
    bookWith3: "用Relux預約",
  },
  hotelpage: {
    labels: {
      name: "飯店名稱",
      desc: "描述",
      price: "價格",
      location: "位置",
      reference: "引用",
      book: "book at",
      info: "資訊",
    },
  },
  jobs: "tsunagu Japan繫日本 人才招募",
  source: "來源",
  sns: {
    share: "分享",
    pin: "pin",
    tweet: "推特",
  },
  lang: {
    "zh-hant": "繁體中文",
    "zh-hans": "簡體中文",
    en: "英文",
    ja: "日文",
    th: "泰文",
  },
  footer: {
    about: "關於tsunagu Japan",
    tsunagudesc:
      "tsunagu Japan是一個日本旅遊資訊的分享平台。資訊內容包括旅行計劃建議、逗留於日本期間的實用小技巧，以及日本文化的相關知識介紹，讓你的旅途更加充實愉快。我們希望能將日本的全貌呈現於你的眼前！",
    contact: "聯絡我們",
    login: "登錄",
    logout: "登出",
    company: "公司簡介",
    privacy: "隱私權和保護政策",
    terms: "使用規章",
    jobs: "徵才",
    dmc: "For Travel Agencies",
  },
};
