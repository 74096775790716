import React from "react";
import LazyLoad from "react-lazyload";
import Truncate from "react-truncate";

export default class ImageView extends React.Component {
  constructor(props) {
    super(props);
    let image = this.props.image;
    if (this.props.mobile) {
      image = this.props.image + "&d=400x400";
      image = image.replace("/medium/", "/original/");
    } else {
      image = this.props.image + "&d=750x750";
      image = image.replace("/medium/", "/original/");
    }
    image = image.replace(/^http:\/\//i, "https://");

    this.state = {
      runInsta: false,
      image: image,
      savorAdState: this.props.savorAdState,
      src_url: "",
      alt: this.props.alt || "",
      boxLink: this.props.boxLink || "",
      name: "",
    };
  }
  componentDidMount() {
    if (this.props.searchRes != "") {
      this.addInstagram(this.props.searchRes);
    }
  }
  showSource() {
    if (this.props.src != null && this.props.src.text != "") {
      return (
        <figcaption className="img-source">
          {this.props.src.url == "" ? (
            this.props.src.text
          ) : (
            <a href={this.props.src.url} target="_blank">
              {this.props.src.text}
            </a>
          )}
        </figcaption>
      );
    }
    return <figcaption className="img-source">&nbsp;</figcaption>;
  }
  click(evt) {
    if (
      evt.target.tagName == "A" &&
      (this.state.savorAdState == 1 || this.state.savorAdState == 2)
    ) {
      ga("send", "event", "savor_ads_ume", "click", this.props.src.url);
    }
    if (
      evt.target.tagName == "A" &&
      (this.state.savorAdState == 3 || this.state.savorAdState == 4)
    ) {
      ga("send", "event", "bangs_ad", "click", this.props.src.url);
    }
    if (this.state.savorAdState == 5) {
      ga("send", "event", {
        eventCategory: this.props.analyticsCategory,
        eventAction: "click",
        eventLabel: this.props.analyticsLabel,
        transport: "beacon",
      });
    }
  }
  componentWillReceiveProps(newProps) {
    if (
      newProps.image != this.props.image ||
      newProps.savorAdState != this.props.savorAdState ||
      newProps.boxLink != this.props.boxLink
    ) {
      let image = newProps.image;
      if (this.props.mobile) {
        image = newProps.image + "&d=351x351";
      } else {
        image = newProps.image + "&d=750x750";
      }
      this.setState(
        {
          image: image,
          savorAdState: newProps.savorAdState,
          runInsta: false,
          boxLink: newProps.boxLink || "",
        },
        () => {
          if (this.props.searchRes != "") {
            this.addInstagram(newProps.searchRes);
          }
        }
      );
    }
  }
  renderAdBanner() {
    let conf = {};
    if (this.state.alt != "undefined") {
      conf.alt = this.state.alt;
    }
    return (
      <a href={this.props.src.url} onClick={this.click.bind(this)}>
        <img {...conf} src={this.state.image} className="img-responsive" />
      </a>
    );
  }
  renderAd() {
    if (this.state.savorAdState == 5) {
      return this.renderAdBanner();
    }
    if (this.state.savorAdState >= 6) {
      return this.renderSimpleBanner();
    }
    let imageWrapperClass =
      this.state.savorAdState % 2 == 0
        ? "col-xs-12 space-bottom-xs"
        : "col-xs-12 col-sm-4 space-bottom-xs";
    let textWrapperClass =
      this.state.savorAdState % 2 == 0
        ? "col-xs-12 ads-text-wrapper"
        : "col-xs-12 col-sm-8 ads-text-wrapper";
    let title =
      this.props.src.url != this.props.image ? this.props.src.url : "";
    let conf = {};
    if (this.state.alt != "undefined") {
      conf.alt = this.state.alt;
    }
    return (
      <div className={"well imagead0" + this.state.savorAdState}>
        <div className="row">
          <div className="col-xs-12">
            <h3 className="ads-headline">{title}</h3>
          </div>
          <div className={imageWrapperClass}>
            <img {...conf} src={this.state.image} className="img-responsive" />
          </div>
          <div
            className={textWrapperClass}
            onClick={this.click.bind(this)}
            dangerouslySetInnerHTML={{ __html: this.props.src.text }}
          />
        </div>
      </div>
    );
  }
  renderAdBanner() {
    let conf = {};
    if (this.state.alt != "undefined") {
      conf.alt = this.state.alt;
    }
    return (
      <a href={this.props.src.url} onClick={this.click.bind(this)}>
        <img {...conf} src={this.state.image} className="img-responsive" />
      </a>
    );
  }
  renderSimpleBanner() {
    let classImage = "col-xs-12 col-sm-4 space-bottom-xs";
    let classText = "col-xs-12 col-sm-8 ads-text-wrapper";
    if (this.state.savorAdState == 7) {
      classImage = "col-xs-12 space-bottom-xs";
      classText = "col-xs-12 ads-text-wrapper";
    }
    let conf = {};
    if (this.state.alt != "undefined") {
      conf.alt = this.state.alt;
    }
    if (this.state.boxLink != "") {
      return this.renderRelatedBox();
    }
    return (
      <div className={"imagead0" + this.state.savorAdState}>
        <div className="row">
          <div className={classImage}>
            <img {...conf} src={this.state.image} className="img-responsive" />
          </div>
          <div
            className={classText}
            onClick={this.click.bind(this)}
            dangerouslySetInnerHTML={{ __html: this.props.src.text }}
          />
        </div>
      </div>
    );
  }
  renderRelatedBox() {
    return (
      <a
        href={this.state.boxLink}
        target="_blank"
        className="article-link-wrapper in-related-article-wrapper green_border"
      >
        <div className="well border-black" data-text="tJcrafts Recommended">
          <div className="row">
            <div className="col-xs-4 in-prev-image">
              <img
                className="img-preview img-responsive"
                src={this.state.image}
              />
            </div>
            <div className="col-xs-8 related_body">
              <h3>
                <Truncate lines={2} width={this.state.width}>
                  {this.props.src.url}
                </Truncate>
              </h3>
              <div className="desc hidden-xs">
                <Truncate lines={2} width={500} ellipsis={<span>...</span>}>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.src.text }}
                  ></div>
                </Truncate>
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  }
  handleError(error) {
    this.setState({ image: "" });
  }
  addInstagram(url) {
    if (this.state.runInsta) {
      return;
    }
    this.setState({ runInsta: true });
    $.ajax({
      url: "https://graph.facebook.com/v19.0/instagram_oembed?url=" + url + "&access_token=837856693917303|03244a6ea3c5b985b0e822445f8c7329",
    }).done((data) => {
      this.setState({
        runInsta: true,
        image: data.thumbnail_url,
        image_w: data.thumbnail_width,
        image_h: data.thumbnail_height,
        // providerUrl: data.provider_url,
        // src_url: data.author_url,
        name: data.author_name,
      });
    });
  }
  getLazyLoadHeight() {
    return window.innerWidth <= 800 && window.innerHeight <= 60 ? 300 : 500;
  }
  renderSource() {
    if (this.props.ad === true) {
      return this.renderAd();
    }
    if (this.state.image == "" && !this.props.editor) {
      return <div />;
    }
    if (this.props.searchRes != "" && this.props.searchRes != null) {
      return (
        <div className="insta-button-2">
          <p>
            <a href={`https://www.instagram.com/${this.state.name}`} target="_blank">
              <span className="fa fa-instagram" />
              <span className="insta-label">{this.state.name}</span>
            </a>
          </p>

          {this.state.image != "" ? (
            <p>
              <img
                onError={this.handleError.bind(this)}
                src={this.state.image}
                className="img-responsive"
                width={this.state.image_w}
                height={this.state.image_h}
              />
            </p>
          ) : (
            <div className="insta-error">
              <img
                src="https://www.tsunagujapan.com/wp-content/uploads/2016/12/glyph-logo_May2016.png"
                className="img-responsive"
              />
            </div>
          )}
          <a href={this.props.searchRes} className="insta-link" target="_blank">
            embedded from Instagram
          </a>
        </div>
      );
    }
    let imgProps = {
      src: this.state.image,
      className: "img-responsive",
    };
    if (this.props.imageAttributes["width"] != "auto") {
      imgProps.width = this.props.imageAttributes["width"];
    }
    let conf = {};
    if (this.state.alt != "undefined") {
      conf.alt = this.state.alt;
    }
    return (
      <LazyLoad offset={100} height={this.getLazyLoadHeight()}>
        <figure>
          <img {...imgProps} {...conf} />
          {this.showSource()}
        </figure>
      </LazyLoad>
    );
  }
  render() {
    return this.renderSource();
  }
}
